<template>
  <div>
    <!-- Primary Banners -->
    <v-carousel
      v-if="p_banners.length > 0"
      class="mb-5"
      hide-delimiter-background
      :show-arrows="false"
      height="400"
      style="border-radius: 10px"
      :reverse="$i18n.locale == 'ar' ? true : false"
    >
      <v-carousel-item
        v-for="(item, i) in p_banners"
        :key="i"
        :src="`https://majaal-system-files.ams3.cdn.digitaloceanspaces.com/${item.url}`"
      ></v-carousel-item>
    </v-carousel>
    <h1 class="pt-10">{{ $t("home.heading") }}</h1>
    <p style="font-size: 1.3rem" class="pt-2">
      {{ $t("home.sub") }}
    </p>
    <!-- Features -->
    <v-row
      class="pt-5"
      :align="$vuetify.breakpoint.width < 960 ? 'end' : 'center'"
    >
      <v-col cols="6" sm="6" md="6" lg="3">
        <v-row class="my-0">
          <v-col cols="12" md="auto" sm="12" align-self="center"
            ><v-icon color="black" size="60">mdi-scale-balance </v-icon></v-col
          >
          <v-col>
            <h2>{{ $t("home.feature1") }}</h2>
            <p>{{ $t("home.subF1") }}</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" sm="6" md="6" lg="3">
        <v-row class="my-0">
          <v-col cols="12" md="auto" sm="12" align-self="center"
            ><v-icon color="black" size="60">mdi-store-search</v-icon></v-col
          >
          <v-col>
            <h2>{{ $t("home.feature2") }}</h2>
            <p>{{ $t("home.subF2") }}</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" sm="6" md="6" lg="3">
        <v-row class="my-0">
          <v-col cols="12" md="auto" sm="12" align-self="center"
            ><v-icon color="black" size="60">mdi-texture-box</v-icon></v-col
          >
          <v-col>
            <h2>{{ $t("home.feature3") }}</h2>
            <p>{{ $t("home.subF3") }}</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" sm="6" md="6" lg="3">
        <v-row class="my-0">
          <v-col cols="12" md="auto" sm="12" align-self="center"
            ><v-icon color="black" size="60"
              >mdi-clock-time-eight-outline</v-icon
            ></v-col
          >
          <v-col>
            <h2>{{ $t("home.feature4") }}</h2>
            <p>{{ $t("home.subF4") }}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Featured items -->
    <browse
      class="py-9"
      :items="featured"
      :title="$t('home.featured')"
      v-if="featured.length > 0"
    />

    <!-- Secondary Banners -->
    <v-carousel
      v-if="s_banners.length > 0"
      class="mb-5"
      hide-delimiter-background
      :show-arrows="false"
      height="400"
      style="border-radius: 10px"
      :reverse="$i18n.locale == 'ar' ? true : false"
    >
      <v-carousel-item
        v-for="(item, i) in s_banners"
        :key="i"
        :src="`https://majaal-system-files.ams3.cdn.digitaloceanspaces.com/${item.url}`"
      ></v-carousel-item>
    </v-carousel>
    <!-- New and Most viewed -->
    <h2 class="pt-10">{{ $t("item.browse") }}</h2>
    <!-- <v-tabs class="py-7" slider-color="black" slider-size="4" color="black">
      <v-tab
        href="#mviewed"
        class="text-none"
        style="font-size: 1.1rem; letter-spacing: 0px"
      >
        {{ $t("home.mostviewed") }}
      </v-tab>
      <v-tab-item value="mviewed" class="mb-4">
        <browse
          class="py-9 px-6"
          :items="mViewed"
          noTitle
          v-if="mViewed.length > 0"
        />
      </v-tab-item>
      <v-tab
        href="#newest"
        class="text-none text-capitalize"
        style="font-size: 1.1rem; letter-spacing: 0px"
      >
        {{ $t("home.newest") }}
      </v-tab>
      <v-tab-item value="newest" class="mb-4">
        <browse
          class="py-9 px-6"
          :items="newest"
          noTitle
          v-if="newest.length > 0"
        />
      </v-tab-item>
      <v-spacer></v-spacer>
      <v-tab
        to="/login"
        class="text-none"
        style="font-size: 1.1rem; letter-spacing: 0px"
      >
        {{ $t("home.viewAll") }}
      </v-tab>
    </v-tabs> -->

    <v-tabs
      class="py-7"
      slider-color="black"
      slider-size="4"
      color="black"
      v-model="tab"
      background-color="transparent"
      dark
    >
      <v-tab
        :key="1"
        class="text-none"
        style="font-size: 1.1rem; letter-spacing: 0px; color: black"
      >
        {{ $t("home.mostviewed") }}
      </v-tab>
      <v-tab
        :key="2"
        class="text-none"
        style="font-size: 1.1rem; letter-spacing: 0px; color: black"
      >
        {{ $t("home.newest") }}
      </v-tab>
      <v-spacer></v-spacer>

      <v-tab
        to="/login"
        class="text-none"
        style="font-size: 1.1rem; letter-spacing: 0px; color: black"
      >
        {{ $t("home.viewAll") }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item class="mb-4" :key="1">
        <browse
          class="py-9 px-6"
          :items="mViewed"
          noTitle
          v-if="mViewed.length > 0"
        />
      </v-tab-item>
      <v-tab-item class="mb-4" :key="2">
        <browse
          class="py-9 px-6"
          :items="newest"
          noTitle
          v-if="newest.length > 0"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-row class="flex-column py-10">
      <h1 class="text-center">{{ $t("home.ctaHeading") }}</h1>
      <p style="font-size: 1.3rem; width: 60%" class="pt-2 text-center mx-auto">
        {{ $t("home.ctaSub") }}
      </p>
      <div class="mx-auto py-2">
        <v-btn
          @click="$router.push('/signup')"
          color="black"
          x-large
          dark
          style="letter-spacing: 0px"
          >{{ $t("home.createAccount") }}</v-btn
        >
      </div>
    </v-row>
  </div>
</template>


<script>
import { mapState } from "vuex";

export default {
  name: "Home",
  components: {
    Browse: () => import("./Components/Browse.vue"),
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    ...mapState({
      p_banners: (state) => state.bannersModule.p_banners,
      s_banners: (state) => state.bannersModule.s_banners,
      featured: (state) => state.itemsModule.featured,
      mViewed: (state) => state.itemsModule.mViewed,
      newest: (state) => state.itemsModule.newest,
    }),
  },
  created() {
    this.getPrimaryBanners();
    this.getSecondaryBanners();
    this.getFeatured();
    this.getMViewed();
    this.getNewest();
  },

  methods: {
    getPrimaryBanners() {
      // this.loading = true;
      this.$store
        .dispatch("bannersModule/getPrimaryBanners")
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    getSecondaryBanners() {
      // this.loading = true;
      this.$store
        .dispatch("bannersModule/getSecondaryBanners")
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    getFeatured() {
      // this.loading = true;
      this.$store
        .dispatch("itemsModule/getFeatured")
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    getMViewed() {
      // this.loading = true;
      this.$store
        .dispatch("itemsModule/getMViewed")
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    getNewest() {
      // this.loading = true;
      this.$store
        .dispatch("itemsModule/getNewest")
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          // this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.item {
  /* width: 300%;
  height: 200px; */
  border-radius: 3px;
  background: #f5f5f5;
}
</style>